import React from "react";
import LessonLayout from "~/layouts/lesson";
import Introduction from "~/layouts/introduction";

import BSCSLogo from '~/images/landing-page-phone.jpg';



const Page = () => {
  return (
      <LessonLayout
        current_step={0}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <Introduction
          alt="tablet"
          image={BSCSLogo}
          description="The internet is a great source of information. Sometimes it can be hard to choose which websites might be best to look at. In this lesson, you will learn about different features of websites and how these features can help you decide which websites are worth looking at more deeply. "
        />

      </LessonLayout>
  )
}

export default Page;
